import React from "react";
import Footer from "../components/Footer/Footer";
import Nav from "../components/Nav/Nav";

export default function HomeLayout({ children }) {
  return (
    <>
      <Nav />
      <main
        className="min-h-screen bg-blue-100"
      >
        {children}
      </main>
      <Footer />
    </>
  );
}
