import React from "react";

export default function Footer() {
  return (
    <footer class="p-4 bg-white shadow md:px-6 md:py-8 bg-gray-900">
      <div class="sm:flex sm:items-center sm:justify-between">
        <a class="flex items-center mb-4 sm:mb-0">
          <img src="/images/logo.webp" class="h-[100px]" alt="logo" />
        </a>
        {/* <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 text-gray-400">
          <li>
            <a href="#" class="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="#" class="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#" class="mr-4 hover:underline md:mr-6 ">
              Licensing
            </a>
          </li>
          <li>
            <a href="#" class="hover:underline">
              Contact
            </a>
          </li>
        </ul> */}
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto" />
      <span class="block text-sm text-gray-300 sm:text-center">
        <div className="py-2">
          <a href="/" class="hover:underline">
            trunkey.dev
          </a>
        </div>
        <span className="mr-1">© {new Date().getFullYear()}</span>. All Rights Reserved.
      </span>
    </footer>
  );
}
