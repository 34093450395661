import './App.css';
// import 'antd/dist/reset.css';

import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import Login from './pages/Auth/Login';
import DailyReportPage from './pages/DailyReport/DailyReportPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path='/' element={<Login />}></Route> */}
        <Route exact path='/' element={<DailyReportPage/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
